<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingCountToday') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingCountToday }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingCountThisMonth') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingCountThisMonth }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.dailyRentsAndTransfers') }}</span>
                <span class="d-block">
                  {{ mainBannerData?.drivingTypeDailyRentCount + '/' + mainBannerData?.drivingTypeTransfersCount }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.drivingTotalAmount') }}</span>
                <span class="d-block">{{ mainBannerData?.drivingTotalAmount }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.driversCount') }}</span>
                <span class="d-block">{{ mainBannerData?.driversCount }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('dashboardPage.passengerCount') }}</span>
                <span class="d-block">{{ mainBannerData?.passengerCount }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" lg="4" xl="6">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.drivingsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="drivingInNext3DaysHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingInNext3Days"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="showEvent({ nativeEvent: false, event: item })"
          multi-sort>
          <template v-slot:item.price="{ item }">
            {{ item.currency.symbol + item.price }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.inNext3Days') }}
                </v-toolbar-title>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <tr v-bind="attrs" v-on="on" @click="showEvent({ nativeEvent: false, event: item })">
                  <td>{{ item.number }}</td>
                  <td>{{ item.currency.symbol + item.price }}</td>
                  <td>{{ item.from_location_name }}</td>
                  <td>{{ item.vehicle?.name }}</td>
                </tr>
              </template>

              <driving-info-tooltip :item="item" :drivers="drivers" />
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="6" lg="4" xl="3">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.drivingsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="drivingNotPaidOrConfirmedHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="drivingNotPaidOrConfirmed"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="openDrivshowEvent({ nativeEvent: false, event: item })"
          multi-sort>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.notConfirmedOrPaid') }}
                </v-toolbar-title>
              </template>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndUp">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <tr v-bind="attrs" v-on="on" @click="showEvent({ nativeEvent: false, event: item })">
                  <td>{{ item.number }}</td>
                  <td>
                    <v-icon>{{ parseInt(item.paid) ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  </td>
                  <td>
                    <v-icon>{{ parseInt(item.client_confirmed) ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  </td>
                </tr>
              </template>

              <driving-info-tooltip :item="item" :drivers="drivers" />
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="12" lg="4" xl="3">
        <v-row>
          <v-col cols="12" md="6" lg="12">
            <v-data-table
              :footer-props="{
                pageText: `{0}-{1} ${$t('tables.of')} {2}`,
                itemsPerPageText: $t('tables.driversByPage'),
                itemsPerPageOptions: [5, 10, 20, 50, 100],
              }"
              :headers="driversInNext5DaysHeaders"
              :no-data-text="$t('tables.noDataText')"
              :no-results-text="$t('tables.noDataText')"
              :items="driversInNext5Days"
              class="elevation-1 cursor-pointer"
              :items-per-page="5"
              @click:row="openDrivingWithDriver"
              multi-sort>
              <template v-slot:item.full_name="{ item }">
                {{ item.first_name + ' ' + item.last_name }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <template>
                    <v-toolbar-title>
                      {{ $t('tables.inNext5Days') }}
                    </v-toolbar-title>
                  </template>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" md="6" lg="12">
            <v-data-table
              :footer-props="{
                pageText: `{0}-{1} ${$t('tables.of')} {2}`,
                itemsPerPageText: $t('tables.reviewsByPage'),
                itemsPerPageOptions: [5, 10, 20, 50, 100],
              }"
              :headers="passengerReviewsLast5Headers"
              :no-data-text="$t('tables.noDataText')"
              :no-results-text="$t('tables.noDataText')"
              :items="passengerReviewsLast5"
              class="elevation-1"
              :items-per-page="5"
              multi-sort
              hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <template>
                    <v-toolbar-title>
                      {{ $t('tables.latestReviews') }}
                    </v-toolbar-title>
                  </template>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <driving-modal :selectedElement="selectedElement" />
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import router from '@/router';
import { defaultDriving, defaultDashboardBannerData } from '@/mixins/default-items';
import DrivingModal from './drivings/DrivingModal.vue';
import {
  drivingInNext3DaysHeaders,
  drivingNotPaidOrConfirmedHeaders,
  driversInNext5DaysHeaders,
  passengerReviewsLast5Headers,
} from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';
import { changeColorBasedOnProposalStatus } from '@/utils/changeColorBasedOnProposalStatus';
import DrivingInfoTooltip from '@/components/drivings/DrivingInfoTooltip.vue';

export default {
  name: 'DashboardPage',
  props: [],
  components: { DrivingModal, DrivingInfoTooltip },
  data: () => ({
    mainBannerData: {},
    drivingInNext3Days: [],
    drivingNotPaidOrConfirmed: [],
    driversInNext5Days: [],
    passengerReviewsLast5: [],
    drivers: [],
    selectedElement: null,
  }),
  created() {
    this.selectedElement = Object.assign({}, defaultDriving);
    this.mainBannerData = Object.assign({}, defaultDashboardBannerData);
    this.getDashboardData();
    this.getAllDrivers();
  },
  computed: {
    drivingInNext3DaysHeaders() {
      return drivingInNext3DaysHeaders(i18n);
    },
    drivingNotPaidOrConfirmedHeaders() {
      return drivingNotPaidOrConfirmedHeaders(i18n);
    },
    driversInNext5DaysHeaders() {
      return driversInNext5DaysHeaders(i18n);
    },
    passengerReviewsLast5Headers() {
      return passengerReviewsLast5Headers(i18n);
    },
  },
  methods: {
    async getDashboardData() {
      await this.$store.dispatch('dashboard/getDashboardData').then((res) => {
        this.mainBannerData = res.data.mainBannerData;
        this.drivingInNext3Days = res.data.drivingInNext3Days.map((item) => {
          item.distance = parseFloat(item.distance);
          item.price = parseFloat(item.price);
          item.paid = parseInt(item.paid);
          item.from_location ? (item.from_location_name = JSON.parse(item.from_location).name) : '';
          item.to_location ? (item.to_location_name = JSON.parse(item.to_location).name) : '';
          let proposalStatus = item.drivingProposal?.drivingProposalStatus;
          item.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          item.pickup_time_original = item.pickup_time;
          // we keep format pickup time for preview
          item.pickup_time = formatDateTime(item.pickup_time);
          item.expected_drop_off_time = formatDateTime(item.expected_drop_off_time);
          item.expected_comeback_time = formatDateTime(item.expected_comeback_time);
          item.created_at = formatDateTime(item.created_at);
          item.driving_number = item.number + ` [${item.public_number}]`

          return item;
        });
        this.drivingNotPaidOrConfirmed = res.data.drivingNotPaidOrConfirmed.map((item) => {
          item.distance = parseFloat(item.distance);
          item.price = parseFloat(item.price);
          item.paid = parseInt(item.paid);
          item.from_location ? (item.from_location_name = JSON.parse(item.from_location).name) : '';
          item.to_location ? (item.to_location_name = JSON.parse(item.to_location).name) : '';
          let proposalStatus = item.drivingProposal?.drivingProposalStatus;
          item.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          item.pickup_time_original = item.pickup_time;
          // we keep format pickup time for preview
          item.pickup_time = formatDateTime(item.pickup_time);
          item.expected_drop_off_time = formatDateTime(item.expected_drop_off_time);
          item.expected_comeback_time = formatDateTime(item.expected_comeback_time);
          item.created_at = formatDateTime(item.created_at);
          item.driving_number = item.number + ` [${item.public_number}]`

          return item;
        });
        this.driversInNext5Days = res.data.driversInNext5Days;
        this.passengerReviewsLast5 = res.data.passengerReviewsLast5;
      });
    },
    async getAllDrivers() {
      await this.$store.dispatch('users/getAllUsers', { drivers: true }).then((res) => {
        this.drivers = res.data.length
          ? res.data.map((user) => {
              user.full_name = user.profile?.full_name || 'N/A';
              return user;
            })
          : [];
      });
    },
    showEvent({ nativeEvent, event }) {
      this.selectedElement = Object.assign({}, event);

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    openDrivingWithDriver(item) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: 'TabularView',
        query: { driver_id: item.id },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.getDashboardData();
      },
    },
  },
};
</script>
